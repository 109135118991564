import { defineStore } from "pinia";
import BackendService from "@/services/BackendService"

export const useVariableStore = defineStore('variable', {
  state: () => ({
    loading: false,
    loadedVariableOfVariableGroups: [], // List of all loaded variable groups
  }),
  getters: {
    isLoading () {
        return this.loading
    },
    getVariableOfVariableGroups () {
        return this.loadedVariableOfVariableGroups
    }
  },
  actions: {
    async fetchAllVariablesByVariableGroup(selectedVariableGroups, organisationAccountName) {
        this.loading = true
        for(let varGrp of selectedVariableGroups) {
            // Check if variable groups isn't already loaded
            if(this.loadedVariableOfVariableGroups.find(x => x.id == varGrp.id)==undefined) {
                await BackendService.getAllVariableByVariableGroups(organisationAccountName, varGrp.variableGroupProjectReferences[0].projectReference.name , varGrp.id)
                .then((res) => this.loadedVariableOfVariableGroups.push(res.data))
                .catch((e) => console.error(e)) // TO DO: Error handling
            }
        }
        // Remove loadedVariableOfVariableGroups that are not selected anymore
        this.loadedVariableOfVariableGroups = this.loadedVariableOfVariableGroups.filter(
            (variableOfVariableGroup) => 
                selectedVariableGroups.find((variableGroup) => variableGroup.id == variableOfVariableGroup.id)!=undefined
        )
        this.loading = false
    },
    async updateCreateVariableToVariableGroup(variable, variableGroupProjectReferences, organisationAccountName) {
        let addedVariable =
        {
            key: variable.key,
            value: variable.value,
            isSecret: variable.isSecret ? true : false,
            isReadonly: variable.isReadonly ? true : false,
            status: 0,
            variableGroupProjectReference: variableGroupProjectReferences[0]
        }
        await BackendService.postUpdateCreateVariableGroup(organisationAccountName, [addedVariable])
        .then(() => {
            let variableGroup = this.getVariableOfVariableGroups.find(variableGroup => variableGroup.variableGroupProjectReferences == variableGroupProjectReferences)
            let temp = variableGroup.variables.find(item => item.key == variable.key)
            temp ? temp.value = variable.value : variableGroup.variables.push(variable)
        })
        .catch((e) => console.error(e)) // TO DO: Error handling
    },
    getVariablesByVariableGroup(variableGroupName, projectId) {
        console.log(this.loadedVariableOfVariableGroups)
        return this.loadedVariableOfVariableGroups.find(x => (x.name == variableGroupName) && (x.variableGroupProjectReferences[0].projectReference.id == projectId)).variables
    },
    setSelectedProjects (projects) {
        this.selectedProjectsId = projects.map(project => project.id)
    }
  }
})